import React, { Component } from "react";
import { ethers } from "ethers";
import {
  networks,
  setupNetwork,
  setProvider,
  getProvider,
  setSigner,
  getSigner,
  getNetwork,
  setNetwork,
  getWnatBalance,
  getClaimable,
  claim,
  getUnclaimable,
} from "./logic/web3";

const AppState = React.createContext({});

class AppStateProvider extends Component {
  state = {
    connected: false,
    address: "",
    Balance: 0,
    wrappedbBalance: 0,
    claimable: 0,
    unclaimable: 0,
    network: "",
  };

  connect = async (network) => {
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      await this.checkNetwork(network);
      await this.checkAccount();
      if (getNetwork() !== undefined) {
        const address = await getSigner().getAddress();
        const balance = parseFloat(
          ethers.utils.formatUnits(await getSigner().getBalance(), 18)
        );
        await this.setState({
          connected: true,
          address: address,
          Balance: balance,
          network: network,
        });
      }
    } catch (err) {
      console.error(err);
      if (err.code === 4001) console.error("PLease connect to MM");
      else {
        console.error(err);
        await this.setState({
          connected: false,
          network: "",
        });
      }
    }
  };

  checkNetwork = async (network) => {
    window.ethereum.on("chainChanged", () => window.location.reload());
    let chainId = await window.ethereum.request({ method: "eth_chainId" });

    if (network === undefined) {
      if (networks[chainId] !== undefined) {
        await this.handleChainChanged(chainId);
      } else if (window.ethereum.isMetaMask) {
        await this.setState({
          connected: false,
          network: "",
        });
      } else {
        await this.setState({
          connected: false,
          network: "",
        });
      }
    } else {
      if (networks[chainId] !== undefined && chainId === network) {
        setNetwork(networks[chainId]);
        await this.handleChainChanged(chainId);
      } else if (window.ethereum.isMetaMask) {
        await setupNetwork(network);
        if (
          chainId === (await window.ethereum.request({ method: "eth_chainId" }))
        )
          await this.setState({
            connected: false,
            network: "",
          });
      } else {
        await this.setState({
          connected: false,
          network: "",
        });
      }
    }
  };

  handleChainChanged = async (chainId) => {
    setNetwork(networks[chainId]);
    setProvider(new ethers.providers.Web3Provider(window.ethereum));
    setSigner(getProvider().getSigner());

    if (getNetwork() !== undefined) {
      const address = await getSigner().getAddress();
      const balance = parseFloat(
        ethers.utils.formatUnits(await getSigner().getBalance(), 18)
      );
      await this.setState({
        connected: true,
        address: address,
        Balance: balance,
        network: chainId,
      });
    } else {
      await this.setState({
        connected: false,
        network: "",
      });
    }
  };

  checkAccount = async () => {
    let accounts = await window.ethereum.request({ method: "eth_accounts" });
    window.ethereum.on("accountsChanged", () => window.location.reload());
    await this.handleAccountsChanged(accounts);
  };

  logout = async () => {
    await this.setState({
      connected: false,
      address: "",
      Balance: 0,
      wrappedbBalance: 0,
      claimable: 0,
      unclaimable: 0,
      network: "",
    });
  };

  handleAccountsChanged = async (accounts) => {
    if (accounts.length === 0) {
      await this.setState({
        connected: false,
        network: "",
      });
    } else {
      if (getNetwork() !== undefined) {
        const network = await window.ethereum.request({
          method: "eth_chainId",
        });
        const address = await getSigner().getAddress();
        const balance = parseFloat(
          ethers.utils.formatUnits(await getSigner().getBalance(), 18)
        );
        await this.setState({
          connected: true,
          address: address,
          Balance: balance,
          network: network,
        });
      }
    }
  };

  checkWeb3 = async () => {
    if (typeof window.ethereum !== "undefined") {
      setProvider(new ethers.providers.Web3Provider(window.ethereum));
      setSigner(getProvider().getSigner());
      try {
        await getSigner().getAddress();
        await this.checkNetwork();
        await this.checkAccount();
        if (getNetwork() !== undefined) {
          const address = await getSigner().getAddress();
          const balance = parseFloat(
            ethers.utils.formatUnits(await getSigner().getBalance(), 18)
          );
          const network = await window.ethereum.request({
            method: "eth_chainId",
          });
          await this.setState({
            connected: true,
            address: address,
            Balance: balance,
            network: network,
          });
        }
      } catch (err) {
        await this.setState({
          connected: false,
          network: "",
        });
      }
    } else {
      console.error("Please install MM!");
      await this.setState({
        connected: false,
        network: "",
      });
    }
  };

  getWBalance = async () => {
    const b = await getWnatBalance();
    await this.setState({ wrappedbBalance: b });
  };

  getRewards = async () => {
    const claimable =
      Math.floor(
        (await getClaimable().catch((e) => {
          return 0;
        })) * 100
      ) / 100;
    const unclaimable =
      Math.floor(
        (await getUnclaimable().catch((e) => {
          return 0;
        })) * 100
      ) / 100;
    await this.setState({
      claimable: claimable,
      unclaimable: unclaimable,
    });
  };

  componentDidMount() {
    this.checkWeb3();
    this.getWBalance();
    this.getRewards();
  }

  reset = async () => {
    this.checkWeb3();
    this.getWBalance();
    this.getRewards();
  };

  SwitchNetwork = async () => {
    let net = this.state.network === "0xe" ? "0x13" : "0xe";
    await setupNetwork(net);
    await this.reset();
  };

  claimTx = async (rewardAddress) => {
    await claim(rewardAddress).catch((e) => {
      return e;
    });
    this.reset();
  };

  render() {
    return (
      <AppState.Provider
        value={{
          connected: this.state.connected,
          address: this.state.address,
          Balance: this.state.Balance,
          wrappedbBalance: this.state.wrappedbBalance,
          claimable: this.state.claimable,
          unclaimable: this.state.unclaimable,
          network: this.state.network,
          connect: this.connect,
          claimTx: this.claimTx,
          reset: this.reset,
          SwitchNetwork: this.SwitchNetwork,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </AppState.Provider>
    );
  }
}

export default AppState;

export { AppStateProvider };
