import "./navbar.css";
import React, { useContext, useState } from "react";
import AppState from "../../AppState";
import LogoutButton from "../../assets/logout.svg";

const Navbar = () => {
  const context = useContext(AppState);
  const network = context.network;
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={isOpen ? "navbar open" : "navbar"}>
      <div className="right_section">
        <h1 className="logo_navbar">Lena Instruments</h1>
        <button className="toggle_button" onClick={handleClick}>
          <i className="fas fa-bars"></i>
        </button>
      </div>
      <ul className="menu_navbar">
        <li>
          <a href="/wrap">
            <p>Wrap</p>
            <p className="numberSpan">1</p>
          </a>
        </li>
        <li>
          <a href="/delegate">
            <p>Delegate</p>
            <p className="numberSpan">2</p>
          </a>
        </li>
        <li>
          <a href="/delegations">
            <p>My Delegations</p>
            <p className="numberSpan">3</p>
          </a>
        </li>
        <li>
          <a href="/unwrap">
            <p>Unwrap</p>
            <p className="numberSpan">4</p>
          </a>
        </li>
      </ul>
      <div className="btns">
        <span style={{ marginRight: "20px" }} title="Switch Network">
          <div style={{ cursor: "pointer" }} onClick={context.SwitchNetwork}>
            <div className="ball"></div>
            <span style={{ fontFamily: "Lena", fontWeight: 400 }}>
              {network === "0xe" ? "Flare" : "Songbird"}
            </span>
          </div>
        </span>
        {/*
        <button onClick={context.SwitchNetwork} className="textImage">
          <p>Switch network</p>
          <img alt="Switch Network" className="image numberSpan" src={Switch} />
        </button> */}
        <button onClick={context.logout} title="Logout">
          <img src={LogoutButton} alt="logout" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
