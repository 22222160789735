import React, { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import Navbar from "../components/navbar/Navbar";
import { getSigner, wrap, claim } from "./../logic/web3";
import AppState from "./../AppState";
import loader from "./../loader1.svg";
import Logo from "./../logo.svg";
import { Link, NavLink } from "react-router-dom";
import WSGBICON from "./../assets/WSGB.png";
import SGBICON from "./../assets/SGB.png";
import CopyToClipboard from "react-copy-to-clipboard";
import WFLRICON from "./../assets/wFlareLogo.png";
import FLRICON from "./../assets/FlareLogo.png";
import "../components/wrap_main/wrap_main.css";
import "../components/sidebar/sidebar.css";
import ExploreIcon from "@mui/icons-material/ExploreOutlined";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";

const Wrap = () => {
  const context = useContext(AppState);
  const [appState, setAppState] = useState({ loading: false });
  const [balance, setbalance] = useState(0);
  const { network } = context;
  useEffect(() => {
    const getBalance = async () => {
      setAppState({ loading: true });
      const balance = parseFloat(
        ethers.utils.formatUnits(await getSigner().getBalance(), 18)
      );
      setbalance(balance);
      setAppState({ loading: false });
    };
    getBalance();
  }, []);

  const WrapTx = async () => {
    const wrap_amount = document.getElementById("wrap_amount").value;
    if (!wrap_amount || parseFloat(wrap_amount) <= 0) return;
    setAppState({ loading: true });
    const result = await wrap(wrap_amount).catch((e) => {
      return e;
    });
    context.reset();
    setAppState({ loading: false, result });
  };

  const setMax = () => {
    if (balance <= 0.25) return;
    document.getElementById("wrap_amount").value =
      document.getElementById("wrap_amount").max;
  };

  const address = context.address;
  const Balance = context.Balance;
  const wrappedbBalance = context.wrappedbBalance;
  const claimable = context.claimable;
  const unclaimable = context.unclaimable;
  const [rewardadd, setRewardadd] = useState(address);
  const SimpleAddress = address.substring(0, 8) + "..." + address.substring(34);

  const claimReward = async () => {
    if (claimable > 0) {
      if (rewardadd.length === 42) {
        setAppState({ loading: true });
        try {
          await claim(rewardadd);
          setAppState({
            loading: false,
            result: { claim: "You successfully claimed your rewards." },
          });
          context.reset();
        } catch (e) {
          setAppState({
            loading: false,
            result: { claim: "Something went wrong. Please try again" },
          });
        }
      } else {
        setAppState({ loading: true });
        setAppState({
          loading: false,
          result: { claim: "Please check your reward address." },
        });
      }
    } else {
      setAppState({ loading: true });
      setAppState({
        loading: false,
        result: { claim: "You have nothing to claim." },
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="main">
        <div className="left_section">
          <div className="sidebar_container">
            <h1 className="title">My wallet</h1>
            <div className="wallet_content">
              <div className="address_section">
                <span className="subtitle">ADDRESS</span>
                <div className="addressContent">
                  <p style={{ fontSize: "15px" }}>{SimpleAddress}</p>
                  <div>
                    <CopyToClipboard text={address} title="Tap to copy">
                      <button className="copy_address">
                        <CopyIcon style={{ width: "15px", color: "grey" }} />
                      </button>
                    </CopyToClipboard>
                    <span
                      style={{ marginLeft: "15px" }}
                      title="tap to go to the explorer"
                    >
                      <Link
                        target={"_blank"}
                        to={{
                          pathname:
                            (network === "0xe"
                              ? "https://flare"
                              : "https://songbird") +
                            "-explorer.flare.network/address/" +
                            address,
                        }}
                        style={{ color: "grey" }}
                      >
                        <ExploreIcon style={{ width: "18px", color: "grey" }} />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="coin_section">
              <h1 className="subtitle">ASSETS</h1>
              <div className="songBird">
                <img src={network === "0xe" ? FLRICON : SGBICON} alt="logo" />
                <span className="sonbirgName">
                  {network === "0xe" ? "FLR" : "SGB"}
                </span>
                <span className="value">
                  {Number.parseFloat(Balance).toFixed(2)}
                </span>
                <span className="sbg">{network === "0xe" ? "FLR" : "SGB"}</span>
              </div>
              <div className="WsongBird">
                <img
                  src={network === "0xe" ? WFLRICON : WSGBICON}
                  alt="wrapped logo"
                />
                <span className="WsonbirgName">
                  W{network === "0xe" ? "FLR" : "SGB"}
                </span>
                <span className="Wvalue">
                  {Number.parseFloat(wrappedbBalance).toFixed(2)}
                </span>
                <span className="Wsbg">
                  W{network === "0xe" ? "FLR" : "SGB"}
                </span>
              </div>
            </div>
            <div className="statistics_section">
              <h1 className="subtitle">REWARDS</h1>
              <div className="statistics_content">
                <p className="labelInput">
                  Claim my rewards to the below address:
                </p>
                <input
                  className="inputtext"
                  type="text"
                  placeholder="Reward address"
                  value={rewardadd}
                  onChange={(e) => setRewardadd(e.target.value)}
                />
                <div className="unclaimed_rewards">
                  <div>
                    <p>Unclaimed rewards</p>
                    <p className="period">from previous epochs</p>
                  </div>
                  <p>
                    {" "}
                    {Number.parseFloat(claimable).toFixed(2)}{" "}
                    {network === "0xe" ? "FLR" : "SGB"}
                  </p>
                </div>
                <div className="pending_rewards">
                  <div>
                    <p>Pending rewards</p>
                    <p className="period">from current epoch</p>
                  </div>
                  <p>
                    {" "}
                    {Number.parseFloat(unclaimable).toFixed(2)}{" "}
                    {network === "0xe" ? "FLR" : "SGB"}
                  </p>
                </div>
              </div>
            </div>
            <button className="claim_btn" onClick={claimReward}>
              Claim Rewards
            </button>
          </div>
        </div>
        <div className="right_section">
          <div className="wrap_section">
            {appState.loading ? (
              <div className="loading_section">
                <img alt="loading" src={loader} className="load" />
                <img alt="logo" src={Logo} className="logo" />
              </div>
            ) : !appState.result ? (
              <>
                <h1> Wrap</h1>
                <div className="input_section">
                  <input
                    type="number"
                    placeholder={`Wrap my ${network === "0xe" ? "FLR" : "SGB"}`}
                    id="wrap_amount"
                    min={0}
                    max={balance - 0.25}
                  />
                  <div>
                    <button
                      className={balance > 0.25 ? "max" : "none"}
                      onClick={setMax}
                    >
                      Max
                    </button>
                    <i
                      className={`fa-solid fa-info tooltip ${
                        network === "0xe" ? "FLR" : "SGB"
                      }`}
                      style={{
                        width: "20px",
                        height: "20px",
                        background: "grey",
                        borderRadius: "50%",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "10px",
                      }}
                    ></i>
                  </div>
                </div>
                <button className="wrap_btn" onClick={WrapTx}>
                  Wrap
                </button>
              </>
            ) : appState.result.claim ? (
              <div className="error_section">
                <h1>
                  {appState.result.claim ===
                  "You successfully claimed your rewards."
                    ? "Well done!"
                    : "Error"}
                </h1>
                <p> {appState.result.claim}</p>
                <button
                  className="wrap_btn"
                  onClick={() => setAppState({ loading: false })}
                >
                  Home page
                </button>
              </div>
            ) : appState.result.code ? (
              <div className="error_section">
                <h1>Error</h1>
                <p> Something went wrong. Please try again.</p>
                <button
                  className="wrap_btn"
                  onClick={() => setAppState({ loading: false })}
                >
                  Try again
                </button>
              </div>
            ) : (
              <div className="success_section">
                <h1>Well done!</h1>
                <p>You successfully wrapped your tokens.</p>
                <NavLink className="wrap_btn" to="/delegate">
                  Delegate
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Wrap;
