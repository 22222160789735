import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import "../components/delegation_main/delegation_main.css";
import {
  getWnatBalance,
  getDelegations,
  undelegate,
  getLenaDelegations,
  getAvailableDelegations,
  claim,
} from "./../logic/web3";
import loader from "./../loader1.svg";
import Logo from "./../logo.svg";
import { NavLink, Link } from "react-router-dom";
import Delegation from "../components/delegation/Delegation.jsx";
import AppState from "./../AppState";
import WSGBICON from "./../assets/WSGB.png";
import SGBICON from "./../assets/SGB.png";
import CopyToClipboard from "react-copy-to-clipboard";
import WFLRICON from "./../assets/wFlareLogo.png";
import FLRICON from "./../assets/FlareLogo.png";
import "../components/sidebar/sidebar.css";
import ExploreIcon from "@mui/icons-material/ExploreOutlined";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";

const Delegations = () => {
  const [appState, setAppState] = useState({ loading: true, delegations: [] });
  const [balance, setBalance] = useState(0);
  const { network } = React.useContext(AppState);
  const [delegations, setdelegations] = useState({
    available: 0,
    LenaDelegated: 0,
  });
  const lenaAddress =
    network === "0xe"
      ? "0xdde9BcB57cbA00A9718b67b4074ec6B62C542957"
      : "0xc9AC8F034d295962A6a975b717B691437605Bbb6";

  const context = React.useContext(AppState);
  const address = context.address;
  const Balance = context.Balance;
  const wrappedbBalance = context.wrappedbBalance;
  const claimable = context.claimable;
  const unclaimable = context.unclaimable;
  const [rewardadd, setRewardadd] = useState(address);
  const SimpleAddress = address.substring(0, 8) + "..." + address.substring(34);

  useEffect(() => {
    const getInfo = async () => {
      const delegations = await getDelegations();
      const balance = await getWnatBalance();
      const LenaDelegated = await getLenaDelegations(lenaAddress);
      const available = await getAvailableDelegations(lenaAddress);
      setBalance(balance);
      setAppState({ loading: false, delegations });
      setdelegations({
        available: available,
        LenaDelegated: LenaDelegated,
      });
    };
    getInfo();
  }, [lenaAddress]);

  const UpdateAppState = async () => {
    setAppState({ loading: true, delegations: [] });
    const delegations = await getDelegations();
    setAppState({ loading: false, delegations });
  };

  const UndelegateTx = async (from) => {
    setAppState({ loading: true, delegations: [] });
    const result = await undelegate(from).catch((e) => {
      console.error(e);
      return e;
    });
    setAppState({ loading: false, delegations: [], result });
  };

  const undelegateAll = async () => {
    setAppState({ loading: true, delegations: [] });
    const delegations = await getDelegations();
    const d = await delegations.filter((del) => del.address !== lenaAddress);
    var res = true;
    await Promise.all(
      d.map(async (pro) => {
        await undelegate(pro.address).catch(() => {
          res = false;
        });
      })
    );
    const newDel = await getDelegations();
    setAppState({ loading: false, delegations: newDel, result: { del: res } });
  };

  const claimReward = async () => {
    const newDel = await getDelegations();
    if (claimable > 0) {
      if (rewardadd.length === 42) {
        setAppState({ loading: true, delegations: newDel });
        try {
          await claim(rewardadd);
          setAppState({
            loading: false,
            delegations: newDel,
            result: { claim: "You successfully claimed your rewards." },
          });
          context.reset();
        } catch (e) {
          setAppState({
            loading: false,
            delegations: newDel,
            result: { claim: "Something went wrong. Please try again" },
          });
        }
      } else {
        setAppState({ loading: true, delegations: newDel });
        setAppState({
          loading: false,
          delegations: newDel,
          result: { claim: "Please check your reward address." },
        });
      }
    } else {
      setAppState({ loading: true, delegations: newDel });
      setAppState({
        loading: false,
        delegations: newDel,
        result: { claim: "You have nothing to claim." },
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="main">
        <div className="left_section">
          <div className="sidebar_container">
            <h1 className="title">My wallet</h1>
            <div className="wallet_content">
              <div className="address_section">
                <span className="subtitle">ADDRESS</span>
                <div className="addressContent">
                  <p style={{ fontSize: "15px" }}>{SimpleAddress}</p>
                  <div>
                    <CopyToClipboard text={address} title="Tap to copy">
                      <button className="copy_address">
                        <CopyIcon style={{ width: "15px", color: "grey" }} />
                      </button>
                    </CopyToClipboard>
                    <span
                      style={{ marginLeft: "15px" }}
                      title="tap to go to the explorer"
                    >
                      <Link
                        target={"_blank"}
                        to={{
                          pathname:
                            (network === "0xe"
                              ? "https://flare"
                              : "https://songbird") +
                            "-explorer.flare.network/address/" +
                            address,
                        }}
                        style={{ color: "grey" }}
                      >
                        <ExploreIcon style={{ width: "18px", color: "grey" }} />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="coin_section">
              <h1 className="subtitle">ASSETS</h1>
              <div className="songBird">
                <img src={network === "0xe" ? FLRICON : SGBICON} alt="logo" />
                <span className="sonbirgName">
                  {network === "0xe" ? "FLR" : "SGB"}
                </span>
                <span className="value">
                  {Number.parseFloat(Balance).toFixed(2)}
                </span>
                <span className="sbg">{network === "0xe" ? "FLR" : "SGB"}</span>
              </div>
              <div className="WsongBird">
                <img
                  src={network === "0xe" ? WFLRICON : WSGBICON}
                  alt="wrapped logo"
                />
                <span className="WsonbirgName">
                  W{network === "0xe" ? "FLR" : "SGB"}
                </span>
                <span className="Wvalue">
                  {Number.parseFloat(wrappedbBalance).toFixed(2)}
                </span>
                <span className="Wsbg">
                  W{network === "0xe" ? "FLR" : "SGB"}
                </span>
              </div>
            </div>
            <div className="statistics_section">
              <h1 className="subtitle">REWARDS</h1>
              <div className="statistics_content">
                <p className="labelInput">
                  Claim my rewards to the below address:
                </p>
                <input
                  className="inputtext"
                  type="text"
                  placeholder="Reward address"
                  value={rewardadd}
                  onChange={(e) => setRewardadd(e.target.value)}
                />
                <div className="unclaimed_rewards">
                  <div>
                    <p>Unclaimed rewards</p>
                    <p className="period">from previous epochs</p>
                  </div>
                  <p>
                    {" "}
                    {Number.parseFloat(claimable).toFixed(2)}{" "}
                    {network === "0xe" ? "FLR" : "SGB"}
                  </p>
                </div>
                <div className="pending_rewards">
                  <div>
                    <p>Pending rewards</p>
                    <p className="period">from current epoch</p>
                  </div>
                  <p>
                    {" "}
                    {Number.parseFloat(unclaimable).toFixed(2)}{" "}
                    {network === "0xe" ? "FLR" : "SGB"}
                  </p>
                </div>
              </div>
            </div>
            <button className="claim_btn" onClick={claimReward}>
              Claim Rewards
            </button>
          </div>
        </div>
        <div className="right_section">
          <div
            className={
              appState.delegations.length > 0 && !appState.loading
                ? "delegation_section list"
                : "delegation_section"
            }
          >
            {appState.loading ? (
              <div className="loading_section">
                <img alt="loading" src={loader} className="load" />
                <img alt="logo" src={Logo} className="logo" />
              </div>
            ) : !appState.result ? (
              <>
                <h1 className="delegation_title">My Delegations</h1>
                {appState.delegations.length > 0 ? (
                  <>
                    <table cellSpacing="0" cellPadding="0">
                      <thead>
                        <tr>
                          <th>Provider's name</th>
                          <th>WSGB balance</th>
                          <th>
                            W{network === "0xe" ? "FLR" : "SGB"} delegated
                            amount
                          </th>
                          <th>Percentage</th>
                          {!(
                            appState.delegations.length === 1 &&
                            appState.delegations[0].address === lenaAddress &&
                            appState.delegations[0].amount === 10000
                          ) && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {appState.delegations.map((delegation, index) => {
                          return (
                            <Delegation
                              key={index}
                              balance={balance}
                              delegation={delegation}
                              undelegate={UndelegateTx}
                            />
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="btns">
                      {delegations.available !== 100 && (
                        <button
                          className="redirect_btn"
                          onClick={undelegateAll}
                        >
                          Undelegate from another {"FTSO(s)"}
                        </button>
                      )}

                      {delegations.available !== delegations.LenaDelegated &&
                        delegations.LenaDelegated !== 0 && (
                          <button className="redirect_btn">
                            <NavLink to="/delegate">Delegate more</NavLink>
                          </button>
                        )}
                      {delegations.LenaDelegated === delegations.available &&
                        delegations.LenaDelegated === 100 && (
                          <button className="redirect_btn">
                            <NavLink to="/wrap">Wrap more</NavLink>
                          </button>
                        )}
                      {delegations.LenaDelegated === 0 &&
                        delegations.available !== 0 && (
                          <button className="redirect_btn">
                            <NavLink to="/delegate">Delegate to Lena </NavLink>
                          </button>
                        )}
                    </div>
                  </>
                ) : (
                  <>
                    <p>You aren't delegating your votes.</p>
                    <button className="redirect_btn">
                      <NavLink to="/delegate">
                        Delegate to Lena Instruments
                      </NavLink>
                    </button>
                  </>
                )}
              </>
            ) : appState.result.claim ? (
              <div className="error_section">
                <h1>
                  {appState.result.claim ===
                  "You successfully claimed your rewards."
                    ? "Well done!"
                    : "Error"}
                </h1>
                <p> {appState.result.claim}</p>
                <button
                  className="redirect_btn"
                  onClick={() => window.location.reload(false)}
                >
                  Home page
                </button>
              </div>
            ) : appState.result.code || appState.result.del === false ? (
              <div className="error_section">
                <h1>Error</h1>
                <p> Something went wrong. Please try again.</p>
                <button onClick={UpdateAppState} className="redirect_btn">
                  Try Again
                </button>
              </div>
            ) : (
              <div className="success_section">
                <h1>Well done!</h1>
                <p>You successfully undelegated your tokens.</p>
                <button className="redirect_btn">
                  <NavLink to="/delegate">Go to delegate Page</NavLink>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Delegations;
