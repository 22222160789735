import Navbar from "../components/navbar/Navbar";
import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  delegate,
  claim,
  getAvailableDelegations,
  getLenaDelegations,
  getWnatBalance,
  getDelegations,
  undelegate,
} from "./../logic/web3";
import "../components/delegate_main/delegate_main.css";
import loader from "./../loader1.svg";
import Logo from "./../logo.svg";
import AppState from "./../AppState";
import WSGBICON from "./../assets/WSGB.png";
import SGBICON from "./../assets/SGB.png";
import CopyToClipboard from "react-copy-to-clipboard";
import WFLRICON from "./../assets/wFlareLogo.png";
import FLRICON from "./../assets/FlareLogo.png";
import "../components/sidebar/sidebar.css";
import ExploreIcon from "@mui/icons-material/ExploreOutlined";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";

const Delegate = () => {
  const [appState, setAppState] = useState({ loading: true, amount: 100 });
  const { network } = React.useContext(AppState);
  const lenaAddress =
    network === "0xe"
      ? "0xdde9BcB57cbA00A9718b67b4074ec6B62C542957"
      : "0xc9AC8F034d295962A6a975b717B691437605Bbb6";
  const [delegation, setdelegation] = useState({
    balance: 0,
    available: 100,
    delegated: 0,
  });

  useEffect(() => {
    const getInfos = async () => {
      const balance = await getWnatBalance();
      const available = await getAvailableDelegations(lenaAddress);
      const delegated = await getLenaDelegations(lenaAddress);
      setdelegation({ balance, available, delegated });
      setAppState({ loading: false, amount: available });
    };
    getInfos();
  }, [lenaAddress]);

  const DelegateTx = async () => {
    setAppState({ loading: true });
    const result = await delegate(appState.amount, lenaAddress).catch((e) => {
      console.error(e);
      return e;
    });
    const delegated = await getLenaDelegations(lenaAddress);
    setAppState({ loading: false, result, amount: delegated });
  };

  const UndelegateAll = async () => {
    setAppState({ loading: true });

    const delegations = await getDelegations();
    const d = await delegations.filter((del) => del.address !== lenaAddress);
    let res = true;

    await Promise.all(
      d.map(async (pro) => {
        await undelegate(pro.address).catch(() => {
          res = false;
        });
      })
    );
    setAppState({ loading: false, result: { del: res } });
  };

  const context = React.useContext(AppState);
  const address = context.address;
  const Balance = context.Balance;
  const wrappedbBalance = context.wrappedbBalance;
  const claimable = context.claimable;
  const unclaimable = context.unclaimable;
  const [rewardadd, setRewardadd] = useState(address);
  const SimpleAddress = address.substring(0, 8) + "..." + address.substring(34);

  const claimReward = async () => {
    if (claimable > 0) {
      if (rewardadd.length === 42) {
        setAppState({ loading: true });
        try {
          await claim(rewardadd);
          setAppState({
            loading: false,
            result: { claim: "You successfully claimed your rewards." },
          });
          context.reset();
        } catch (e) {
          setAppState({
            loading: false,
            result: { claim: "Something went wrong. Please try again." },
          });
        }
      } else {
        setAppState({ loading: true });
        setAppState({
          loading: false,
          result: { claim: "Please check your reward address." },
        });
      }
    } else {
      setAppState({ loading: true });
      setAppState({
        loading: false,
        result: { claim: "You have nothing to claim." },
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="main">
        <div className="left_section">
          <div className="sidebar_container">
            <h1 className="title">My wallet</h1>
            <div className="wallet_content">
              <div className="address_section">
                <span className="subtitle">ADDRESS</span>
                <div className="addressContent">
                  <p style={{ fontSize: "15px" }}>{SimpleAddress}</p>
                  <div>
                    <CopyToClipboard text={address} title="Tap to copy">
                      <button className="copy_address">
                        <CopyIcon style={{ width: "15px", color: "grey" }} />
                      </button>
                    </CopyToClipboard>
                    <span
                      style={{ marginLeft: "15px" }}
                      title="tap to go to the explorer"
                    >
                      <Link
                        target={"_blank"}
                        to={{
                          pathname:
                            (network === "0xe"
                              ? "https://flare"
                              : "https://songbird") +
                            "-explorer.flare.network/address/" +
                            address,
                        }}
                        style={{ color: "grey" }}
                      >
                        <ExploreIcon style={{ width: "18px", color: "grey" }} />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="coin_section">
              <h1 className="subtitle">ASSETS</h1>
              <div className="songBird">
                <img src={network === "0xe" ? FLRICON : SGBICON} alt="logo" />
                <span className="sonbirgName">
                  {network === "0xe" ? "FLR" : "SGB"}
                </span>
                <span className="value">
                  {Number.parseFloat(Balance).toFixed(2)}
                </span>
                <span className="sbg">{network === "0xe" ? "FLR" : "SGB"}</span>
              </div>
              <div className="WsongBird">
                <img
                  src={network === "0xe" ? WFLRICON : WSGBICON}
                  alt="wrapped logo"
                />
                <span className="WsonbirgName">
                  W{network === "0xe" ? "FLR" : "SGB"}
                </span>
                <span className="Wvalue">
                  {Number.parseFloat(wrappedbBalance).toFixed(2)}
                </span>
                <span className="Wsbg">
                  W{network === "0xe" ? "FLR" : "SGB"}
                </span>
              </div>
            </div>
            <div className="statistics_section">
              <h1 className="subtitle">REWARDS</h1>
              <div className="statistics_content">
                <p className="labelInput">
                  Claim my rewards to the below address:
                </p>
                <input
                  className="inputtext"
                  type="text"
                  placeholder="Reward address"
                  value={rewardadd}
                  onChange={(e) => setRewardadd(e.target.value)}
                />
                <div className="unclaimed_rewards">
                  <div>
                    <p>Unclaimed rewards</p>
                    <p className="period">from previous epochs</p>
                  </div>
                  <p>
                    {" "}
                    {Number.parseFloat(claimable).toFixed(2)}{" "}
                    {network === "0xe" ? "FLR" : "SGB"}
                  </p>
                </div>
                <div className="pending_rewards">
                  <div>
                    <p>Pending rewards</p>
                    <p className="period">from current epoch</p>
                  </div>
                  <p>
                    {" "}
                    {Number.parseFloat(unclaimable).toFixed(2)}{" "}
                    {network === "0xe" ? "FLR" : "SGB"}
                  </p>
                </div>
              </div>
            </div>
            <button className="claim_btn" onClick={claimReward}>
              Claim Rewards
            </button>
          </div>
        </div>
        <div className="right_section">
          <div className="delegate_section">
            {appState.loading ? (
              <div className="loading_section">
                <img alt="loading" src={loader} className="load" />
                <img alt="logo" src={Logo} className="logo" />
              </div>
            ) : !appState.result ? (
              <>
                <h1>Delegate</h1>
                {delegation.balance > 0 ? (
                  <>
                    {delegation.available !== delegation.delegated && (
                      <>
                        <p>{appState.amount} %</p>
                        <input
                          type="range"
                          id="delegate"
                          name="delegate"
                          min={delegation.delegated}
                          max={delegation.available}
                          step="1"
                          defaultValue={delegation.available}
                          style={{
                            background: `linear-gradient(to right, var(--blue) 0%, var(--blue)  ${
                              (appState.amount * 100) / delegation.available
                            }%, #7EC8E3 ${
                              (appState.amount * 100) / delegation.available
                            }%, #7EC8E3 100%)`,
                          }}
                          onChange={() => {
                            setAppState({
                              loading: false,
                              amount: document.getElementById("delegate").value,
                            });
                          }}
                        />
                      </>
                    )}

                    {delegation.available === 100 &&
                      delegation.delegated !== 0 && (
                        <>
                          <p className="text_ftso">
                            You are already delegating{" "}
                            <strong>{delegation.delegated}% </strong>
                            to Lena Instruments
                          </p>
                        </>
                      )}
                    {delegation.available < 100 ? (
                      <>
                        <p className="text_ftso">
                          You are currently delegating{" "}
                          <strong>{100 - delegation.available}%</strong> of your
                          wrapped {network === "0xe" ? "FLR" : "SGB"} to other
                          FTSOs and can only delegate the remaining
                          <strong> {delegation.available}%</strong> to Lena
                          Instruments
                        </p>
                        <div className="btns">
                          <button
                            className="delegate_btn"
                            onClick={UndelegateAll}
                          >
                            Undelegate from another FTSO
                          </button>
                          <button onClick={DelegateTx} className="delegate_btn">
                            Delegate {appState.amount} %
                          </button>
                        </div>
                      </>
                    ) : (
                      <button onClick={DelegateTx} className="delegate_btn">
                        Delegate {appState.amount} %
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <p>
                      You don't have enough{" "}
                      <span>W{network === "0xe" ? "FLR" : "SGB"}.</span>
                    </p>
                    <button className="delegate_btn">
                      <NavLink to="/wrap">
                        Wrap your{" "}
                        <span>{network === "0xe" ? "FLR" : "SGB"}</span>
                      </NavLink>
                    </button>
                  </>
                )}
              </>
            ) : appState.result.claim ? (
              <div className="error_section">
                <h1>
                  {appState.result.claim ===
                  "You successfully claimed your rewards."
                    ? "Well done!"
                    : "Error"}
                </h1>
                <p> {appState.result.claim}</p>
                <button
                  className="delegate_btn"
                  onClick={() => setAppState({ loading: false })}
                >
                  Home page
                </button>
              </div>
            ) : appState.result.code ? (
              <div className="error_section">
                <h1>Error</h1>
                <p> Something went wrong. Please try again.</p>
                <button
                  className="delegate_btn"
                  onClick={() =>
                    setAppState({
                      loading: false,
                      amount: delegation.available,
                    })
                  }
                >
                  Try again
                </button>
              </div>
            ) : appState.result.del === false ? (
              <div className="success_section">
                <h1>Error:</h1>
                <p> Something went wrong. Please try again.</p>
                <button
                  className="delegate_btn"
                  onClick={() => window.location.reload(false)}
                >
                  Try again
                </button>
              </div>
            ) : appState.result.del === true ? (
              <div className="success_section">
                <h1>Well done!</h1>
                <p>You successfully undelegated your tokens.</p>
                <button
                  className="delegate_btn"
                  onClick={() => window.location.reload(false)}
                >
                  Delegate Now
                </button>
              </div>
            ) : (
              <div className="success_section">
                <h1>Well done!</h1>
                <p>You successfully delegated your tokens.</p>
                <div className="btns">
                  {delegation.available !== appState.amount && (
                    <button
                      onClick={() => window.location.reload(false)}
                      className="delegate_btn"
                    >
                      Delegate More
                    </button>
                  )}
                  <button className="delegate_btn">
                    <NavLink to="/delegations">
                      Go to your delegation's dashboard
                    </NavLink>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Delegate;
