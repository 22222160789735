import React, { useState } from "react";
import AppState from "../../AppState";
import "./welcome.css";
import FLR from "../../assets/FlareLogo.png";
import SGB from "../../assets/SGBLogo.png";
import { useHistory } from "react-router-dom";

const Welcome = () => {
  const context = React.useContext(AppState);
  const connect = context.connect;
  const [pop, setpop] = useState(false);
  const his = useHistory("");

  const handlePop = () => {
    setpop(!pop);
  };

  return (
    <>
      <div className={pop ? "activepop" : ""}>
        <div className="welcome_container">
          <div className="welcome_section">
            <div className="upper" style={{ margin: "100px 55px" }}>
              <h1>Lena Instruments</h1>
              <h2>Flare Network Delegation Service.</h2>
            </div>
            <div className="lower">
              <p>
                By clicking "Connect Metamask" you acknowledge that you have
                read , understand and completely agree to these <br />
                <span style={{ display: "block", marginTop: "4px" }}>
                  <a href="/terms">Terms of Services</a>.
                </span>
              </p>
              <div>
                <button className="connectbtn" onClick={handlePop}>
                  Connect Metamask
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pop ? (
        <>
          <div className="pop">
            <button className="closeBtn" onClick={handlePop}>
              <div className="outer">
                <div className="inner">
                  <label>Back</label>
                </div>
              </div>
            </button>
            <div className="btns">
              <button
                className="connectbtn FLR"
                onClick={async () => {
                  await connect("0xe");
                  if (connect) {
                    his.push("/wrap");
                    context.reset();
                  }
                }}
              >
                <img src={FLR} width={120} alt="FLR logo" />
                <p>FLR</p>
              </button>
              <p style={{ fontSize: "50px", color: "gray" }}>/</p>
              <button
                className="connectbtn SGB"
                onClick={async () => {
                  await connect("0x13");
                  if (connect) {
                    his.push("/wrap");
                    context.reset();
                  }
                }}
              >
                <img src={SGB} width={120} alt="SGB logo" />
                <p>SGB</p>
              </button>
            </div>
            <div style={{ fontSize: "30px", color: "gray", marginTop: "40px" }}>
              <p>Select your network</p>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Welcome;
