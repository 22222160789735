import React from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import AppState from "./AppState";
import Welcome from "./components/welcome/Welcome";
import Wrap from "./pages/Wrap";
import Unwrap from "./pages/Unwrap";
import Delegations from "./pages/Delegations";
import Delegate from "./pages/Delegate";
import Terms from "./pages/terms";
import "./App.css";

function App() {
  const { connected } = React.useContext(AppState);

  return (
    <div className="app">
      <Router>
        {connected ? (
          <Switch>
            <Route path="/wrap" component={Wrap} />
            <Route path="/unwrap" component={Unwrap} />
            <Route path="/delegate" component={Delegate} />
            <Route path="/delegations" component={Delegations} />
            <Route path="/terms" component={Terms} />
            <Route path="/" component={Welcome} />
            <Route>
              <Redirect to="/wrap" />
            </Route>
          </Switch>
        ) : (
          <>
            <Switch>
              <Route path="/terms" component={Terms} />
              <Route path="/" component={Welcome} />
              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
