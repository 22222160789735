import React from "react";
import "./delegation.css";
import { knownFTSO } from "../../logic/web3";
import AppState from "../../AppState";
import { NavLink } from "react-router-dom";

const Delegation = (props) => {
  const { network } = React.useContext(AppState);

  const lenaAddress =
    network === "0xe"
      ? "0xdde9BcB57cbA00A9718b67b4074ec6B62C542957"
      : "0xc9AC8F034d295962A6a975b717B691437605Bbb6";

  return (
    <tr className="delegation_container">
      <td>
        {knownFTSO[props.delegation.address.toLowerCase()] ||
          knownFTSO[props.delegation.address] ||
          props.delegation.address.substring(0, 6) +
            "..." +
            props.delegation.address.substring(36)}
      </td>
      <td>
        {Number.parseFloat(props.balance).toFixed(3) +
          ` W${network === "0xe" ? "FLR" : "SGB"}`}
      </td>

      <td>
        {(props.balance * (props.delegation.amount / 10_000)).toFixed(3) +
          ` W${network === "0xe" ? "FLR" : "SGB"}`}
      </td>
      <td>{props.delegation.amount / 100 + "%"}</td>
      {props.delegation.address === lenaAddress &&
        props.delegation.amount !== 10000 && (
          <td>
            <button className="undelegate_btn" title="Delegation more">
              <NavLink to="/delegate">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </NavLink>
            </button>
          </td>
        )}
      {props.delegation.address !== lenaAddress && (
        <td>
          <button
            className="undelegate_btn"
            title="Revoke delegation"
            onClick={() => {
              props.undelegate(props.delegation.address);
            }}
          >
            <i className="fa fa-minus" aria-hidden="true"></i>
          </button>
        </td>
      )}
    </tr>
  );
};

export default Delegation;
