import React, { useState } from "react";
import "./terms_main.css";
import { useHistory } from "react-router-dom";
import AppState from "../../AppState";
import FLR from "../../assets/FlareLogo.png";
import SGB from "../../assets/SGBLogo.png";

const TermsMain = () => {
  const context = React.useContext(AppState);
  const connect = context.connect;
  const [pop, setpop] = useState(false);
  const his = useHistory("");

  const handlePop = () => {
    setpop(!pop);
  };

  return (
    <div className={pop ? "activepop terms" : "terms"}>
      <div>
        <section className="navbar">
          <a href="/">
            <h1 className="logo_navbar">Lena Instruments</h1>
          </a>
        </section>
        <section className="disclaimer">
          <p className="bold">Terms of Services</p>
          <p>
            IMPORTANT: By wrapping and/or delegating or simply accessing or
            interacting with any of the services and products (the{" "}
            <span className="bold">“Services”</span>) and/or Flare and/or
            SongBird Smart Contracts (the{" "}
            <span className="bold">“Smart Contracts”</span> accessible via{" "}
            <span className="bold">https://delegation.lena.tech</span>), you
            acknowledge that you have read, understood, and completely agree to
            the Terms of Services hereunder, as updated and amended from time to
            time. If you do not agree to be bound by these Terms of Services or
            with any subsequent amendments, changes or updates, you should not
            access or use any of the Services or Smart Contracts accessible via{" "}
            <span className="bold">https://delegation.lena.tech</span> , and if
            you do access or use any of the Services or Smart Contracts, you
            will be bound by these Terms of Services, as updated and amended
            from time to time; your only recourse in the case of your
            unwillingness to be bound by the Terms of Services is to stop using
            all of the Services and Smart Contracts accessible via{" "}
            <span className="bold">https://delegation.lena.tech</span>. These
            Terms of Services were last updated on{" "}
            <span className="bold">October 24th, 2022</span>.
          </p>
          <p>
            You acknowledge that trading, wrapping and delegating Digital Tokens
            (including but not limited to SongBird and Flare Digital Tokens,
            known as SGB and FLR) involves significant risks of financial loss.
            You should not commit to using{" "}
            <span className="bold">https://delegation.lena.tech</span> related
            products if you are not prepared to lose entirely. Market prices for
            Digital Tokens products can be volatile and highly unpredictable.
            Losses on a position of a Digital Token product can occur quickly
            and be total and immediate. Whether the future market price of a
            Digital Token will move up or down is speculative and unknown.
          </p>
          <p>
            You should not engage in wrapping and/or delegating any Digital
            Token on <span className="bold">https://delegation.lena.tech</span>{" "}
            unless you understand the products you are about to use and their
            risk. You agree that if you interact with the Smart Contracts or
            Services available on{" "}
            <span className="bold">https://delegation.lena.tech</span> , you are
            doing so at your own risk and that you irrevocably agree and
            acknowledge that Lena Instruments and its Associate will not assume
            any liability or responsibility, and no Associate nor Lena
            Instruments shall have any liability or responsibility for any
            Losses directly or indirectly arising out of or related to utilising
            the Smart Contracts or the Services or the Digital Tokens (including
            but not limited to SGB and FLR) related to{" "}
            <span className="bold">https://delegation.lena.tech</span>
          </p>
          <p>
            You also irrevocably agree and acknowledge that Lena Instruments and
            its Associate will not assume any liability or responsibility for
            any losses directly or indirectly arising out of or related to
            entering the wrong URL, hence accessing the wrong website. The one
            and only URL, the one and only website offering the above-mentioned
            Services and Smart Contracts is the following:{" "}
            <span className="bold">https://delegation.lena.tech</span>
          </p>
          <p>
            You acknowledge that the funds you use to interact with{" "}
            <span className="bold">https://delegation.lena.tech</span> Smart
            Contracts are free and have never been tied to any criminal
            activity, for example, involvement in money laundering or terrorism
            funding schemes. You acknowledge that you are the holder of the
            necessary licences to utilise the funds interacting with{" "}
            <span className="bold">https://delegation.lena.tech</span>
          </p>
          <p>
            You understand that{" "}
            <span className="bold">https://delegation.lena.tech</span> offers
            you technology services, the Services, as well as interactions with
            smart contracts, the Smart Contracts but is not the custodian of
            your tokens, nor offer you asset management services nor hold your
            tokens nor keeps them for you/from you in any way. You are
            responsible for managing your private keys and{" "}
            <span className="bold">https://delegation.lena.tech</span> will
            never ask you for your private keys.
          </p>
          <p>
            You acknowledge that you are the holder of the necessary licences to
            utilise the funds interacting with{" "}
            <span className="bold">https://delegation.lena.tech</span> if for
            the example you are not the ultimate beneficial owner of the said
            funds.
          </p>
        </section>
      </div>
      {pop ? (
        <>
          <div className="pop">
            <button className="closeBtn" onClick={handlePop}>
              <div className="outer">
                <div className="inner">
                  <label>Back</label>
                </div>
              </div>
            </button>
            <div className="btns">
              <button
                className="connectbtn FLR"
                onClick={async () => {
                  await connect("0xe");
                  if (connect) {
                    his.push("/wrap");
                    context.reset();
                  }
                }}
              >
                <img src={FLR} width={120} alt="FLR logo" />
                <p>FLR</p>
              </button>
              <p style={{ fontSize: "50px", color: "gray" }}>/</p>
              <button
                className="connectbtn SGB"
                onClick={async () => {
                  await connect("0x13");
                  if (connect) {
                    his.push("/wrap");
                    context.reset();
                  }
                }}
              >
                <img src={SGB} width={120} alt="SGB logo" />
                <p>SGB</p>
              </button>
            </div>
            <div style={{ fontSize: "30px", color: "gray" }}>
              <p>Select your network</p>
            </div>
          </div>
        </>
      ) : (
        <div>
          <p
            style={{
              textAlign: "center",
              margin: "15px",
              fontFamily: "Proxima Nova",
            }}
          >
            By clicking "Connect Metamask" you acknowledge that you have read ,
            understand and completely agree to these <br />
            <span style={{ display: "block", marginTop: "4px" }}>
              <a href="/terms" style={{ color: "blue" }}>
                Terms of Services
              </a>
            </span>
          </p>
          <button className="connectbtn" onClick={handlePop}>
            Connect Metamask
          </button>
        </div>
      )}
    </div>
  );
};

export default TermsMain;
